* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  --color-pride-red: #e40303;
  --color-pride-orange: darkorange;
  --color-pride-yellow: #ffed00;
  --color-pride-green: #008026;
  --color-pride-dark-green: #003d13;
  --color-pride-blue: #24408e;
  --color-pride-purple: #732982;
  --color: #333;
  --bg: #fafafa;
  --color-link: var(--color-pride-green);
  --color-link-hover: var(--color-pride-red);
  --color-link-header: var(--bg);
  --color-link-header-hover: var(--bg);
  --color-footer: var(--bg);
  --color-link-footer: #afdca7;
  --color-link-footer-hover: var(--color-pride-yellow);
  --color-h1: var(--color-pride-green);
  --color-h2: var(--color);
  --color-h3: var(--color);
  --color-bullet: var(--color-pride-yellow);
  --bg-body: var(--color-pride-green);
  --bg-main: var(--bg);
  --bg-header: var(--color-pride-green);
  --bg-header-pinned: var(--color-pride-dark-green);
  --bg-footer-engage: var(--color-pride-yellow);
  --bg-footer-legal: var(--color-pride-dark-green);
  --font-family: Verdana, sans-serif;
  --font-family-title: Helvetica, sans-serif;
  --font-size-h1: 32px;
  --font-size-h2: 24px;
  --font-size-h3: 16px;
  --gap-l: 48px;
  --gap-m: 32px;
  --gap-sm: 24px;
  --gap-s: 16px;
  --gap-xs: 8px;
  --gap-xxs: 4px;
  background-color: var(--bg-body);
  font-family: var(--font-family);
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
}

body.tablet {
  --font-size-h1: 24px;
  --font-size-h2: 20px;
  --font-size-h3: 16px;
  --gap-l: 36px;
  --gap-m: 24px;
  --gap-sm: 16px;
  --gap-s: 12px;
  --gap-xs: 4px;
  --gap-xxs: 2px;
}

body.mobile {
  --font-size-h1: 20px;
  --font-size-h2: 18px;
  --font-size-h3: 16px;
  --gap-l: 24px;
  --gap-m: 16px;
  --gap-sm: 12px;
  --gap-s: 8px;
  --gap-xs: 4px;
  --gap-xxs: 2px;
}

select {
  appearance: none;
  padding: var(--gap-xs);
  border: 1px solid var(--color-link-header);
  border-radius: var(--gap-xxs);
  color: var(--color-link-header);
  background-color: #0000;
  background-image: url("language.8be62b97.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: right var(--gap-xxs) center;
  padding-right: 36px;
}

.tablet select, .mobile select, .xs select {
  color: #0000;
  background-position: center;
  background-size: 36px 36px;
  border: none;
  width: 48px;
  height: 48px;
}

ul.list {
  padding-left: var(--gap-s);
  margin-left: 0;
  list-style: none;
}

ul.list li {
  padding-left: 24px;
  padding-bottom: var(--gap-xs);
  padding-top: var(--gap-xs);
  position: relative;
}

ul.list li:before {
  content: "";
  background-color: var(--color-bullet);
  background-image: url("sunflower.e9af984f.webp");
  background-size: contain;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  display: block;
  position: absolute;
  left: 0;
}

ul.social {
  gap: var(--gap-m);
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

ul.social a {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 26px;
  font-weight: bold;
}

ul.social a.facebook {
  background-image: url("facebook.0b063146.svg");
  background-size: 22px 22px;
}

ul.social a.instagram {
  background-image: url("instagram.fb165e07.svg");
}

a {
  color: var(--color-link);
  font-weight: bold;
  text-decoration: none;
  transition: color .3s;
}

a:hover {
  color: var(--color-link-hover);
}

a.anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -80px;
}

header {
  z-index: 10;
  padding: 0 var(--gap-m) var(--gap-l) var(--gap-m);
  background-color: var(--bg-header);
  padding-top: var(--gap-s);
  padding-bottom: var(--gap-s);
  justify-content: center;
  transition: background-color .5s;
  display: flex;
  position: sticky;
  top: -1px;
}

header .content {
  padding: 0 var(--gap-s);
  align-items: center;
  width: 100%;
  max-width: 1280px;
  display: flex;
}

header .content .actions {
  flex-shrink: 1;
}

header.pinned {
  background-color: var(--bg-header-pinned);
  box-shadow: 0 1px 10px #0000004d;
}

header a {
  color: var(--color-link-header);
  gap: var(--gap-s);
  flex-grow: 1;
  display: flex;
}

header a:hover {
  color: var(--color-link-header-hover);
}

header .logo {
  width: 50px;
  height: 50px;
}

header .title {
  font-size: 24px;
  font-style: italic;
  font-family: var(--font-family-title);
  letter-spacing: 0;
  align-self: center;
  margin-top: -2px;
  font-weight: 700;
}

.mobile header .title {
  font-size: 20px;
}

.xs header .title {
  font-size: 16px;
}

main {
  color: var(--color);
  background-color: var(--bg-main);
  padding: var(--gap-m);
  margin: 0 var(--gap-m) var(--gap-m) var(--gap-m);
  border-radius: var(--gap-xs);
  align-self: center;
  max-width: 1024px;
  min-height: 60vh;
  box-shadow: 5px 5px 10px #0003;
}

.intro {
  float: right;
  margin-left: var(--gap-m);
  margin-bottom: var(--gap-m);
  border-radius: var(--gap-xs);
  width: 100%;
  max-width: 400px;
  height: 260px;
  display: block;
}

.intro span {
  border-radius: var(--gap-xs);
  background-image: url("migrationsbeirat.5dd9ea7e.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.intro:hover {
  border-color: var(--color-link-hover);
}

.tablet .intro {
  float: unset;
  max-width: 400px;
  height: 200px;
  margin: 0 auto;
}

.mobile .intro {
  height: 150px;
}

footer {
  color: var(--color-footer);
}

footer .engage {
  padding: var(--gap-s);
  background-color: var(--bg-footer-engage);
  justify-content: center;
  display: flex;
}

footer .legal {
  background-color: var(--bg-footer-legal);
  padding: var(--gap-s);
  text-align: center;
}

footer .legal ul {
  gap: var(--gap-m);
  justify-content: center;
  padding: 0;
  list-style: none;
  display: flex;
}

footer .legal a {
  color: var(--color-link-footer);
  font-weight: normal;
}

footer .legal a:hover {
  color: var(--color-link-footer-hover);
}

h2, h3 {
  color: var(--color-subtitle);
}

h1 {
  font-family: var(--font-family-title);
  font-size: var(--font-size-h1);
  color: var(--color-h1);
  margin-top: 0;
}

.mobile h1, .tablet h1 {
  text-align: center;
}

h2 {
  font-size: var(--font-size-h2);
  color: var(--color-h2);
}

h3 {
  font-size: var(--font-size-h3);
  color: var(--color-h3);
}

h2, h3 {
  margin-top: var(--gap-l);
  margin-bottom: var(--gap-m);
}

.members ul {
  flex-flow: wrap;
  justify-content: left;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.members ul li {
  width: 33%;
  font-weight: bold;
}

.tablet .members ul li {
  width: 50%;
}

.mobile .members ul {
  flex-flow: column;
}

.mobile .members ul li {
  width: 100%;
}

.members figure {
  padding: 0;
}

.members figure img {
  border-radius: 50%;
  width: 100%;
  display: none;
}

.members figure:hover a {
  color: var(--color-link-hover);
}

.members figure:hover img {
  border-color: var(--color-link-hover);
}

.members figure figcaption {
  margin-top: var(--gap-s);
  text-align: center;
}

.member.bordered {
  float: right;
  margin-left: var(--gap-s);
  margin-bottom: var(--gap-s);
}

.bordered {
  border: 2px solid #556b2f80;
  padding: 3px;
}

img.member {
  border-radius: 50%;
  width: 256px;
  height: 256px;
  display: block;
}

.mobile img.member, .tablet img.member {
  float: unset;
  margin: 0 auto;
}

/*# sourceMappingURL=index.4f749156.css.map */
