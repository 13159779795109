* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

//@font-face {
//  font-family: 'Gruen';
//  src: url("/src/font/gruen.ttf");
//  font-display: swap;
//}

body {
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;

  --color-pride-red: #E40303;
  --color-pride-orange: darkorange;
  --color-pride-yellow: #FFED00;
  --color-pride-green: #008026;
  --color-pride-dark-green: #003d13;
  --color-pride-blue: #24408E;
  --color-pride-purple: #732982;

  --color: #333333;
  --bg: #fafafa;

  --color-link: var(--color-pride-green);
  --color-link-hover: var(--color-pride-red);
  --color-link-header: var(--bg);
  --color-link-header-hover: var(--bg);
  --color-footer: var(--bg);
  --color-link-footer: #afdca7;
  --color-link-footer-hover: var(--color-pride-yellow);
  --color-h1: var(--color-pride-green);
  --color-h2: var(--color);
  --color-h3: var(--color);
  --color-bullet: var(--color-pride-yellow);

  --bg-body: var(--color-pride-green);
  --bg-main: var(--bg);
  --bg-header: var(--color-pride-green);
  --bg-header-pinned: var(--color-pride-dark-green);
  --bg-footer-engage: var(--color-pride-yellow);
  --bg-footer-legal: var(--color-pride-dark-green);

  --font-family: Verdana, sans-serif;
  --font-family-title: Helvetica, sans-serif;

  --font-size-h1: 32px;
  --font-size-h2: 24px;
  --font-size-h3: 16px;

  --gap-l: 48px;
  --gap-m: 32px;
  --gap-sm: 24px;
  --gap-s: 16px;
  --gap-xs: 8px;
  --gap-xxs: 4px;

  &.pride {

  }

  &.tablet {
    --font-size-h1: 24px;
    --font-size-h2: 20px;
    --font-size-h3: 16px;

    --gap-l: 36px;
    --gap-m: 24px;
    --gap-sm: 16px;
    --gap-s: 12px;
    --gap-xs: 4px;
    --gap-xxs: 2px;
  }

  &.mobile {
    --font-size-h1: 20px;
    --font-size-h2: 18px;
    --font-size-h3: 16px;

    --gap-l: 24px;
    --gap-m: 16px;
    --gap-sm: 12px;
    --gap-s: 8px;
    --gap-xs: 4px;
    --gap-xxs: 2px;
  }

  background-color: var(--bg-body);
  font-family: var(--font-family);
}

select {
  appearance: none;
  background-color: transparent;
  padding: var(--gap-xs);
  border: 1px solid var(--color-link-header);
  padding-right: 36px;
  border-radius: var(--gap-xxs);
  color: var(--color-link-header);

  background-image: url("/src/img/language.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: right var(--gap-xxs) center;

  .tablet &, .mobile &, .xs & {
    background-position: center center;
    width: 48px;
    height: 48px;
    background-size: 36px 36px;
    border: none;
    color: transparent;
  }

  option {
  }
}

ul.list {
  list-style: none;
  margin-left: 0;
  padding-left: var(--gap-s);

  li {
    position: relative;
    padding-left: 24px;
    padding-bottom: var(--gap-xs);
    padding-top: var(--gap-xs);

    &:before {
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      left: 0;
      margin-top: 3px;
      content: '';
      background-color: var(--color-bullet);
      background-image: url("/src/img/sunflower.webp");
      background-size: contain;
    }
  }
}

ul.social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: var(--gap-m);

  a {
    font-weight: bold;

    padding-left: 26px;

    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: left center;

    &.facebook {
      background-image: url("/src/img/facebook.svg");
      background-size: 22px 22px;
    }

    &.instagram {
      background-image: url("/src/img/instagram.svg");
    }
  }
}

article {

}

a {
  text-decoration: none;
  color: var(--color-link);
  transition: color 0.3s;
  font-weight: bold;
  &:hover {
    color: var(--color-link-hover);
  }
  &.anchor {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
  }
}

header {
  position: sticky;
  z-index: 10;
  display: flex;
  top: -1px;
  padding: 0 var(--gap-m) var(--gap-l) var(--gap-m);
  background-color: var(--bg-header);
  padding-top: var(--gap-s);
  padding-bottom: var(--gap-s);
  justify-content: center;
  transition: background-color 0.5s;

  .content {
    width: 100%;
    max-width: 1280px;
    padding: 0 var(--gap-s);
    display: flex;
    align-items: center;

    .actions {
      flex-shrink: 1;
    }
  }

  &.pinned {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
    background-color: var(--bg-header-pinned);
  }

  a {
    color: var(--color-link-header);
    display: flex;
    flex-grow: 1;
    gap: var(--gap-s);
    &:hover {
      color: var(--color-link-header-hover);
    }
  }

  .logo {
    height: 50px;
    width: 50px;
  }

  .title {
    font-style: italic;
    font-size: 24px;
    font-family: var(--font-family-title);
    letter-spacing: 0;
    font-weight: 700;
    align-self: center;
    margin-top: -2px;

    .mobile & {
      font-size: 20px;
    }

    .xs & {
      font-size: 16px;
    }
  }
}

main {
  color: var(--color);
  background-color: var(--bg-main);
  box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
  padding: var(--gap-m);
  margin: 0 var(--gap-m) var(--gap-m) var(--gap-m);
  min-height: 60vh;
  border-radius: var(--gap-xs);
  max-width: 1024px;
  align-self: center;
}

.intro {
  display: block;
  width: 100%;
  max-width: 400px;
  height: 260px;
  float: right;
  margin-left: var(--gap-m);
  margin-bottom: var(--gap-m);;
  border-radius: var(--gap-xs);

  span {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("/src/img/migrationsbeirat.webp");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--gap-xs);
  }

  &:hover {
    border-color: var(--color-link-hover);
  }

  .tablet & {
    max-width: 400px;
    height: 200px;
    float: unset;
    margin: 0 auto;
  }

  .mobile & {
    height: 150px;
  }
}


footer {
  color: var(--color-footer);

  .engage {
    display: flex;
    justify-content: center;
    padding: var(--gap-s);

    background-color: var(--bg-footer-engage);
  }

  .legal {
    background-color: var(--bg-footer-legal);
    padding: var(--gap-s);
    text-align: center;

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      gap: var(--gap-m);
      justify-content: center;
    }

    a {
      font-weight: normal;
      color: var(--color-link-footer);
      &:hover {
        color: var(--color-link-footer-hover);
      }
    }
  }
}

h2, h3 {
  color: var(--color-subtitle);
}

h1 {
  margin-top: 0;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h1);
  color: var(--color-h1);

  .mobile &, .tablet & {
    text-align: center;
  }
}

h2 {
  font-size: var(--font-size-h2);
  color: var(--color-h2);
}

h3 {
  font-size: var(--font-size-h3);
  color: var(--color-h3);
}

h2, h3 {
  margin-top: var(--gap-l);
  margin-bottom: var(--gap-m);
}

.members {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;

    li {
      width: 33%;
      font-weight: bold;
    }

    .tablet & {
      li {
        width: 50%;
      }
    }

    .mobile & {
      flex-flow: column;

      li {
        width: 100%;
      }
    }
  }

  figure {
    padding: 0;
    img {
      border-radius: 50%;
      width: 100%;
      display: none;
    }
    &:hover {
      a {
        color: var(--color-link-hover);
      }
      img {
        border-color: var(--color-link-hover);
      }
    }
    figcaption {
      margin-top: var(--gap-s);
      text-align: center;
    }
  }
}

.member.bordered {
  float: right;
  margin-left: var(--gap-s);
  margin-bottom: var(--gap-s);
}

.bordered {
  border: 2px solid rgba(85, 107, 47, 0.5);
  padding: 3px;
}

img {
  &.member {
    display: block;
    border-radius: 50%;
    width: 256px;
    height: 256px;

    .mobile &, .tablet & {
      margin: 0 auto;
      float: unset;
    }
  }
}


